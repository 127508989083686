import { Card, CardContent } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { Heading } from "./common/heading";
import { Quote } from "lucide-react";
import { useCallback, useRef, useState } from "react";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import { cn } from "@/lib/utils";
const feedbackData = [
  {
    name: "Raj Malhotra",
    designation: "Software Engineer",
    description:
      "Shaadivines really rescued us in my wedding. I could never imagine such a" +
      "good use of tech to solve basic day to day life problems. Kudos to the team.",
    image: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/landing-page/images/reviews/RajMalhotra.webp`,
    alt: "Raj Malhotra",
  },
  {
    name: "Sanjana Rawat",
    designation: "Marketing Manager",
    description:
      "I must say, I have saved atleast 30-40% of my budget due to impeccable planning\n" +
      "done by Shaadivines Team. Specially their guests invite and RSVP feature helped us\n" +
      "get accurate guests list and we catered accordingly.",
    image: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/landing-page/images/reviews/SanjanaRawat.webp`,
    alt: "Sanjana Rawat",
  },
  {
    name: "Akshay Agrawal",
    designation: "Financial Analyst",
    description:
      "My sisters wedding was one of the most special day for me and weddingvines saved\n" +
      "our day.\n" +
      "Everyone was amazed with wedding website as rarely anyone has seen something like\n" +
      "this in India",
    image: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/landing-page/images/reviews/AkshayAgrawal.webp`,
    alt: "Akshay Agrawal",
  },
  {
    name: "Raj Malhotra",
    designation: "Product Designer",
    description:
      "Shaadivines really rescued us in my wedding. I could never imagine such a\n" +
      "good use of tech to solve basic day to day life problems. Kudos to the team.",
    image: `https://sv-static-assets.s3.ap-south-1.amazonaws.com/landing-page/images/reviews/RajMalhotra.webp`,
    alt: "Raj Malhotra",
  },
];

const Testimonials = () => {
  const properties = {
    duration: 2000,
    transitionDuration: 500,
    infinite: true,
    arrows: false,
  };

  const [index, setIndex] = useState(0);
  const slideRef = useRef(null);

  const handleIndicatorClick = useCallback((clickedIndex) => {
    if (slideRef.current) {
      slideRef.current.goTo(clickedIndex);
      setIndex(clickedIndex);
    }
  }, []);
  return (
    <div>
      <div className="hidden sm:block">
        <div className="pl-4 pr-2 pt-2 pb-4">
          <Heading title="What Our Clients Say" />
          <Carousel
            opts={{
              align: "start",
            }}
            className="w-full max-w-full"
          >
            <CarouselContent>
              {feedbackData.map((invite, index) => (
                <CarouselItem
                  key={index}
                  className="basis-[45%] lg:basis-[18.666667%] hover:cursor-pointer"
                >
                  <Card className="rounded-2xl">
                    <CardContent className="flex object-cover items-center justify-center p-0">
                      <div className="p-4 w-[300px] relative lg:h-[200px] h-[320px]">
                        <Quote />
                        <p className="text-sm text-gray-500 italic font-sans mt-1">
                          {invite.description}
                        </p>
                      </div>
                    </CardContent>
                  </Card>
                </CarouselItem>
              ))}
            </CarouselContent>
            {/* <CarouselPrevious /> */}
            {/* <CarouselNext /> */}
          </Carousel>
        </div>
      </div>

      <div className="sm:hidden">
        <div className="pl-4 pr-2 pt-2 pb-4">
          <Heading title="What Our Clients Say" classes={"text-center"} />
          <div className="p-4 rounded-[10px]">
            <Slide
              {...properties}
              onChange={(o, newIndex) => setIndex(newIndex)}
              ref={slideRef}
              cssClass="overflow-visible-carousel"
            >
              {feedbackData.map((invite, index) => (
                <TestimonialCard testimonial={invite} />
              ))}
            </Slide>
          </div>
          <div className="-mt-1">
            <div className="flex items-center justify-center gap-2">
              {feedbackData.slice(0, 5).map((_, i) => (
                <div
                  key={i}
                  className={cn(
                    `size-2.5 rounded-full cursor-pointer`,
                    index === i ? "bg-lightRed" : "bg-[#EBEBEB]"
                  )}
                  onClick={() => {
                    handleIndicatorClick(i);
                  }}
                ></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

const TestimonialCard = ({ testimonial }) => {
  return (
    <div className="relative">
      <div className="h-3"></div>
      <Card>
        <CardContent className="p-4 m-0 flex gap-x-4 relative">
          <img
            src="/assets/home/quote.svg"
            alt="quote"
            className="absolute -top-3 right-2 overflow-visible z-10"
          />
          <div className="w-2/12">
            <img
              src={testimonial.image}
              className="rounded-full"
              alt={testimonial.alt}
            />
          </div>
          <div className="w-10/12">
            <h2 className="text-xl font-Jost font-semibold text-lightRed">
              {testimonial.name}
            </h2>
            <p className="text-[0.8rem] font-Jost  font-normal text-black">
              {testimonial.designation}
            </p>
            <p className="text-sm font-Jost mt-2 font-normal text-[#5A5A5A]">
              {testimonial.description}
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

